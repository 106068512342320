import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Wrapper,
  Title,
  Button,
  Loader,
  LoaderContainer,
} from "./styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Card from "../Card";
import Modal from "../Modal";
import loadCircle from "../../assets/loading.png";
import Api from "../../services/Api";

function Carrousel({ title, animes = [] }) {
  const rowRef = useRef();
  const [leftButton, setLeftButton] = useState();
  const [rightButton, setRightButton] = useState();
  const [selectedAnime, setSelectedAnime] = useState(null);
  const [loading, setLoading] = useState(false);

  function scrollRow(toRight = true) {
    const width = window.innerWidth;
    const scrollWidth = rowRef.current.scrollWidth;
    const count = scrollWidth / width;
    const offset = width * 0.04 * 2;
    const scroll = scrollWidth / count - offset;

    rowRef.current.scrollBy(toRight ? scroll : -scroll, 0);

    if (toRight) {
      if (!leftButton) {
        setLeftButton(true);
      }

      if (
        rowRef.current.scrollLeft + scroll + rowRef.current.clientWidth >=
        scrollWidth
      ) {
        setRightButton(false);
      }
    } else {
      if (!rightButton) {
        setRightButton(true);
      }

      if (rowRef.current.scrollLeft - scroll <= 0) {
        setLeftButton(false);
      }
    }
  }

  useEffect(() => {
    if (rowRef.current.scrollWidth > window.innerWidth) {
      setRightButton(true);
    }
  }, []);

  async function handleCardClick(anime) {
    setLoading(true);
    const details = await Api.getDetails(anime.id);
    setSelectedAnime(details);
    setLoading(false);
  }

  async function handleCardClickAnime(anime) {
    setLoading(true);
    const details = await Api.getDetailsAnime(anime.id);
    setSelectedAnime(details);
    setLoading(false);
  }

  return (
    <>
      <Title>{title}</Title>
      <Container>
        {leftButton && (
          <Button
            type="button"
            position="left"
            onClick={() => scrollRow(false)}
          >
            <ChevronLeftIcon
              style={{
                fontSize: "3vw",
              }}
            />
          </Button>
        )}
        {rightButton && (
          <Button type="button" position="right" onClick={() => scrollRow()}>
            <ChevronRightIcon
              style={{
                fontSize: "3vw",
              }}
            />
          </Button>
        )}
        <Row ref={rowRef}>
          <Wrapper>
            {animes.map((anime, key) => (
              <Card
                key={key}
                anime={anime}
                onClick={() =>
                  anime.type === "anime"
                    ? handleCardClickAnime(anime)
                    : handleCardClick(anime)
                }
              />
            ))}
          </Wrapper>
        </Row>
      </Container>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1",
          }}
        >
          <LoaderContainer>
            <Loader src={loadCircle} alt="Loading"></Loader>
          </LoaderContainer>
        </div>
      )}
      {selectedAnime && (
        <Modal anime={selectedAnime} onClose={() => setSelectedAnime(null)} />
      )}
    </>
  );
}

export default Carrousel;
