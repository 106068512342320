import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "./styles";

function Watch() {
  const { id, type } = useParams();

  return (
    <Container>
      <iframe
        title="video"
        src={`https://swx.pages.dev/player/?id=${id}&type=${type}`}
        allow="autoplay; fullscreen"
      />
    </Container>
  );
}

export default Watch;
