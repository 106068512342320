import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from './styles'

function About({ anime }) {

  function drawGenres() {
    if (anime.genres && anime.genres.length > 0) {
      return (
        <li>
          {'Genres: '}
          {anime.genres.map((genre, index) => (
            <Link key={index} to={`#`}>
              {genre.name}
              {index < anime.genres.length - 1 ? ', ' : ''}
            </Link>
          ))}
        </li>
      )
    }
  }

  return (
    <Container>
      {drawGenres()}
      <li>
        {'Status: '}
        <Link to={`#`}>{anime.status}</Link>
      </li>
    </Container>
  )
}

export default About
