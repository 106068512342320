import React, { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import Utility from "../../services/Utility";
import {
  Background,
  Wrapper,
  Container,
  Header,
  Buttons,
  Main,
  Description,
  Information,
} from "./styles";
import Episodes from "../Episodes";
import About from "../About";
import MediaButton from "../Buttons/Media";
import WatchButton from "../Buttons/Watch";
import CloseButton from "../Buttons/Close";

function Modal({ anime, onClose }) {
  const containerRef = useRef();
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    const handleClick = (event) => {
      if (!containerRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      closeModal();
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  function closeModal() {
    setClosing(true);

    setTimeout(() => {
      setClosing(false);
      onClose();
    }, 380);
  }

  const [showFullText, setShowFullText] = useState(false);
  const toggleFullText = () => {
    setShowFullText((prevState) => !prevState);
  };
  const trimmedText = Utility.trimParagraphLong(anime.description);

  return ReactDom.createPortal(
    <Background>
      <Wrapper>
        <Container ref={containerRef} closing={closing}>
          <Header
            background={`"https://ik.imagekit.io/swx/shiori/tr:f-webp/${anime.cover}"`}
          >
            <div>
              <h2>{anime.title}</h2>
              {(() => {
                if (anime.nativeTitle) {
                  return <h3>({anime.nativeTitle.trim()})</h3>;
                } else {
                  return <h3></h3>;
                }
              })()}
              <Buttons>
                {anime.status !== "Upcoming" && (
                  <WatchButton
                    videoId={anime.firstEp}
                    contentType={anime.type}
                  />
                )}
              </Buttons>
            </div>
            <CloseButton onClick={closeModal} />
          </Header>
          <Main>
            <div>
              {anime.description !== "N/A" &&
                anime.description !== "Ongoing" &&
                anime.description !== "Completed" && (
                  <Description>
                    <p>
                      {showFullText ? anime.description + "‏‏‎ " : trimmedText}
                      {trimmedText.length < anime.description.length && (
                        <button
                          style={{
                            background: "none",
                            color: "rgb(119, 119, 119)",
                            cursor: "pointer",
                          }}
                          onClick={toggleFullText}
                        >
                          {showFullText ? "Show less" : "Show more"}
                        </button>
                      )}
                    </p>
                  </Description>
                )}
            </div>
            {anime.status !== "Upcoming" && (
              <Episodes
                animeId={anime.id}
                thumbnail={anime.cover}
                contentType={anime.type}
              />
            )}
            <h3 className="separator">About {anime.title}</h3>
            <About anime={anime} />
          </Main>
        </Container>
      </Wrapper>
    </Background>,
    document.getElementById("modal-root")
  );
}

export default Modal;
