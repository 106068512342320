import React, { useState } from "react";
import Utility from "../../services/Utility";
import {
  Container,
  Main,
  Information,
  Title,
  Description,
  Buttons,
  LoaderContainer,
  Loader,
} from "./styles";
import Modal from "../Modal";
import WatchButton from "../Buttons/Watch";
import InfoButton from "../Buttons/Information";
import loadCircle from "../../assets/loading.png";
import Api from "../../services/Api";

function Featured({ anime }) {
  const [selectedAnime, setSelectedAnime] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleInfoClick(anime) {
    setLoading(true);
    const details = await Api.getDetails(anime.id);
    setSelectedAnime(details);
    setLoading(false);
  }

  return (
    <Container
      background={`https://ik.imagekit.io/swx/shiori/tr:f-webp/${anime.cover}`}
    >
      <Main>
        <Title>
          <h1>{anime.title}</h1>
          {anime.nativeTitle && <h3>({anime.nativeTitle.trim()})</h3>}
        </Title>
        <Description>{Utility.trimParagraph(anime.description)}</Description>
        <Buttons>
          <WatchButton videoId={anime.firstEp} />
          <InfoButton onClick={() => handleInfoClick(anime)} />
        </Buttons>
      </Main>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1",
          }}
        >
          <LoaderContainer>
            <Loader src={loadCircle} alt="Loading"></Loader>
          </LoaderContainer>
        </div>
      )}
      {selectedAnime && (
        <Modal anime={selectedAnime} onClose={() => setSelectedAnime(null)} />
      )}
    </Container>
  );
}

export default Featured;
