import styled from "styled-components";
import { rotation } from "../../assets/styles/animations";

export const Container = styled.div`
  display: inline-block;
  margin-top: clamp(3rem, 2vw, 5rem);
  padding: 3.5vw;
`;

export const Poster = styled.div`
  display: inline-block;
  width: 30vw;
  height: 44vw;

  background: none;

  button {
    width: inherit;
    height: inherit;
    cursor: pointer;
    transition: transform ease 200ms;
    transform: scale(0.9);

    :hover {
      transform: scale(1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 1300px) {
    width: ${30 * 0.6}vw;
    height: ${44 * 0.6}vw;
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled.img`
  mix-blend-mode: screen;
  max-width: max(4rem, 5vw);
  animation: ${rotation} 2s infinite linear;
`;
