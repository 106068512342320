import React, { useEffect, useState } from "react";
import Api from "../../services/Api";
import { Container, Header, Episode, Thumb, Information } from "./styles";
import Dropdown from "../Dropdown";
import FoldButton from "../Buttons/Fold";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const Episodes = ({ animeId, contentType }) => {
  const [episodes, setEpisodes] = useState([]);
  const [folded, setFolded] = useState(true);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const getEpList =
          contentType === "anime"
            ? await Api.getEpisodeListAnime(animeId)
            : await Api.getEpisodeList(animeId);
        const episodesData = getEpList;
        setEpisodes(episodesData.episodes);
      } catch (error) {
        console.error("Error fetching episodes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEpisodes();
  }, [animeId]);

  function handleFolding() {
    setFolded(!folded);
  }

  return (
    <Container>
      <Header>
        <h3>Episodes</h3>
      </Header>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ThreeDots
            visible={true}
            height="65"
            width="65"
            color="#dc242c"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        episodes.map((episode, index) =>
          folded && index >= 5 ? null : (
            <Link
              to={`/watch/${contentType ? contentType : "drama"}/${btoa(
                episode.episodeId
              )}`}
              style={{
                textDecoration: "none",
                display: "block",
                borderTop: index !== 0 ? "1px solid #3f3f3f" : "none",
              }}
              key={episode.episodeId}
            >
              <Episode>
                <Information style={{ width: "100%" }}>
                  <h4 style={{ color: "#e5e5e5" }}>{episode.title}</h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#e5e5e5",
                    }}
                  >
                    <span>{episode.type}</span>
                    <span style={{ textAlign: "right" }}>{episode.time}</span>
                  </div>
                </Information>
              </Episode>
            </Link>
          )
        )
      )}
      {episodes.length > 5 && (
        <FoldButton initialValue={!folded} onClick={handleFolding} />
      )}
    </Container>
  );
};

export default Episodes;
