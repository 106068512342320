import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Poster, Loader, LoaderContainer } from "./styles";
import Api from "../../services/Api";
import Modal from "../../components/Modal";
import loadCircle from "../../assets/loading.png";

const FETCH_DELAY = 1000;
let page = 1;

function Search() {
  const { query } = useParams();
  const containerRef = useRef();
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false); // State for controlling loading overlay
  const [animes, setAnimes] = useState([]);
  const [selectedAnime, setSelectedAnime] = useState(null);

  async function handleScroll() {
    const percentage = Math.abs(
      window.scrollY / (containerRef.current.clientHeight - window.innerHeight)
    );

    if (!fetching && !selectedAnime && percentage > 0.8) {
      setFetching(true);
      page += 1;
      setLoading(true); // Show loading overlay

      Promise.all([
        Api.getSearchResult(query, page),
        Api.getSearchResultAnime(query, page),
      ])
        .then(([result1, result2]) => {
          const combinedResult = result1.concat(result2);
          setAnimes((previous) => previous.concat(combinedResult));
          setFetching(false);
          setLoading(false); // Hide loading overlay
        })
        .catch((error) => {
          console.error(error);
          setFetching(false);
          setLoading(false); // Hide loading overlay
        });
    }
  }

  async function handleCardClick(anime) {
    setLoading(true); // Show loading overlay while fetching details
    const details = await Api.getDetails(anime.id);
    setLoading(false); // Hide loading overlay
    setSelectedAnime(details);
  }

  async function handleCardClickAnime(anime) {
    setLoading(true); // Show loading overlay while fetching details
    const details = await Api.getDetailsAnime(anime.id);
    setLoading(false); // Hide loading overlay
    setSelectedAnime(details);
  }

  useEffect(() => {
    page = 1;
    const timer = setTimeout(() => {
      setLoading(true); // Show loading overlay

      Promise.all([
        Api.getSearchResult(query, page),
        Api.getSearchResultAnime(query, page),
      ])
        .then(([result1, result2]) => {
          const combinedResult = result1.concat(result2);
          setAnimes(combinedResult);
          setLoading(false); // Hide loading overlay
        })
        .catch((error) => {
          console.error(error);
          setLoading(false); // Hide loading overlay
        });
    }, FETCH_DELAY);

    return () => clearTimeout(timer);
  }, [query]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  });

  return (
    <Container ref={containerRef}>
      {animes?.map((anime, key) => (
        <Poster key={key}>
          <button
            onClick={() =>
              anime.type === "anime"
                ? handleCardClickAnime(anime)
                : handleCardClick(anime)
            }
          >
            <img
              src={`https://ik.imagekit.io/swx/shiori/tr:f-webp/${anime.image}`}
              alt={anime.title}
            />
          </button>
        </Poster>
      ))}
      {selectedAnime && (
        <Modal anime={selectedAnime} onClose={() => setSelectedAnime(null)} />
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoaderContainer>
            <Loader src={loadCircle} alt="Loading"></Loader>
          </LoaderContainer>
        </div>
      )}
    </Container>
  );
}

export default Search;
