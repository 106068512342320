import axios from "axios";

class Api {
  constructor() {
    this.kitsu = axios.create({ baseURL: "https://kitsu.io/api/edge" });
    this.drama = axios.create({ baseURL: "https://draapi.azurewebsites.net" });
    this.anime = axios.create({
      baseURL: "https://draapi.azurewebsites.net/anime",
    });
  }

  async getSearchResult(input, page) {
    let query = `/search/${input}?page=${page}`;
    return await this.drama.get(query).then((response) => {
      return response.data.results;
    });
  }
  async getSearchResultAnime(input, page) {
    let query = `/search/${input}?page=${page}`;
    return await this.anime.get(query).then((response) => {
      return response.data.results;
    });
  }

  async getTrending() {
    let query = `/weekly-top`;
    return await this.drama.get(query).then((response) => {
      return response.data.results;
    });
  }

  async getPopularOngoing(page) {
    let query = `/popular-ongoing?page=${page}`;
    return await this.drama.get(query).then((response) => {
      return response.data.results;
    });
  }

  async getPopularCompleted(page) {
    let query = `/popular-completed?page=${page}`;
    return await this.drama.get(query).then((response) => {
      return response.data.results;
    });
  }

  async getPopularAnime(page) {
    let query = `/popular?page=${page}`;
    return await this.anime.get(query).then((response) => {
      return response.data.results;
    });
  }

  async getGenre(genreId, page) {
    let query = `/genre/${genreId}.html?page=${page}`;
    return await this.drama.get(query).then((response) => {
      return response.data.results;
    });
  }

  async getDetails(detailsId) {
    let query = `/details/${detailsId}`;
    return await this.drama.get(query).then((response) => {
      return response.data;
    });
  }

  async getDetailsAnime(detailsId) {
    let query = `/details/${detailsId}`;
    return await this.anime.get(query).then((response) => {
      return response.data;
    });
  }

  async getEpisodeList(detailsId) {
    let query = `/episode-list/${detailsId}`;
    return await this.drama.get(query).then((response) => {
      return response.data;
    });
  }

  async getEpisodeListAnime(detailsId) {
    let query = `/episode-list/${detailsId}`;
    return await this.anime.get(query).then((response) => {
      return response.data;
    });
  }

  async getEpisode(epId) {
    let query = `/episode/${epId}`;
    return await this.drama.get(query).then((response) => {
      return response.data;
    });
  }

  async getNextEpTime(epId) {
    let query = `/next-ep-time/${epId}`;
    return await this.drama.get(query).then((response) => {
      return response.data;
    });
  }

  async getGenres(detailsId) {
    let query = `/details/${detailsId}`;
    return await this.drama.get(query).then((response) => {
      return response.data.genres;
    });
  }

  async getStatus(detailsId) {
    let query = `/status/${detailsId}`;
    return await this.drama.get(query).then((response) => {
      return response.data;
    });
  }
}

export default new Api();
