import React from "react";
import { Button } from "./styles";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

function Watch({ videoId = "", contentType }) {
  return (
    <Button
      title="Watch Now"
      to={`/watch/${contentType ? contentType : "drama"}/${btoa(
        videoId.split("/")[1]
      )}`}
    >
      <div>
        <PlayArrowIcon
          style={{
            fontSize: "clamp(1.5rem, 3vw, 3rem)",
            marginRight: ".5vw",
          }}
        />
        <strong>Watch Now</strong>
      </div>
    </Button>
  );
}

export default Watch;
