import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`).then(
    (registration) => {
      //console.log("Service worker registration succeeded:", registration);
    },
    /*catch*/ (error) => {
      //console.error(`Service worker registration failed: ${error}`);
    }
  );
} else {
  //console.error("Service workers are not supported.");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
