import React from 'react'
import { Container } from './styles'

function Card({ anime, onClick = () => { } }) {
  return (
    <Container>
      <button onClick={onClick}>
        <img
          alt={anime.title}
          src={`https://ik.imagekit.io/swx/shiori/tr:f-webp/${anime.image}`}
        />
      </button>
    </Container>
  )
}

export default Card
