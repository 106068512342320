import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-top: 1.5vw;
    margin-bottom: 0;
    line-height: 2;
  }
`;

export const Episode = styled.div`
  padding: 1rem 0;

  & + & {
    border-top: 1px solid #3f3f3f;
  }

  @media (min-width: 700px) {
    display: flex;
    padding: 2rem 0;
  }

  &:hover {
    background-color: #2a2a2a;
  }
`;

export const Thumb = styled.div`
  display: flex;
  justify-content: center;
  background: #141414;

  img {
    width: 14.5rem;
    height: 8.1rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const Information = styled.div`
  p {
    color: #d2d2d2;
  }

  @media (min-width: 700px) {
    margin-left: 0rem;
  }

  @media (min-width: 1200px) {
    font-size: 80%;
  }
`;
