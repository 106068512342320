import React, { useEffect, useState, Fragment } from "react";
import Api from "../../services/Api";
import { Rows, Row } from "./styles";
import Loading from "../../components/Loading";
import Featured from "../../components/Featured";
import Carrousel from "../../components/Carrousel";

function Browse() {
  const [rows, setRows] = useState([]);
  const [featured, setFeatured] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    function loadLocal() {
      const data = JSON.parse(localStorage.getItem("@dramarush/home"));

      if (data) {
        setRows(data.rows);
        setFeatured(data.featured);
        return true;
      }

      return false;
    }

    async function fetch() {
      setLoading(true);

      const trending = await Api.getTrending();
      const trendingFilter = trending.filter(
        (entry) =>
          entry.cover &&
          entry.cover.includes("-large.png") &&
          entry.description !== "N/A"
      );
      const featured =
        trendingFilter[Math.floor(Math.random() * trendingFilter.length)];
      const rows = [
        {
          title: "Trending",
          animes: trending,
        },
        {
          title: "Popular Ongoing",
          animes: await Api.getPopularOngoing(),
        },
        {
          title: "Popular Completed",
          animes: await Api.getPopularCompleted(),
        },
        {
          title: "Anime",
          animes: await Api.getPopularAnime(),
        },
      ];

      localStorage.setItem(
        "@dramarush/home",
        JSON.stringify({
          rows: rows,
          featured: featured,
        })
      );

      setRows(rows);
      setFeatured(featured);
      setLoading(false);
    }

    if (!loadLocal()) {
      fetch();
    }
  }, []);

  window.onbeforeunload = () => {
    localStorage.clear();
  };

  function drawBrowse() {
    if (featured && rows) {
      return (
        <Fragment>
          <Featured anime={featured} />
          <Rows>
            {rows.map((row, key) => (
              <Row key={key}>
                <Carrousel title={row.title} animes={row.animes} />
              </Row>
            ))}
          </Rows>
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <Loading loading={loading} />
      {!loading && drawBrowse()}
    </Fragment>
  );
}

export default Browse;
